<template>
  <div>
    <title>SIM INVENTORY ~ EDIT DATA LIST ORDER COMPONENT DETAILS</title>
    <section class="content-header">
      <h1>
        Edit Data List Order Component Detail
        <br />
        <h4>Please update Data Transaction List Order Component Detail</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Data Transaction List Order Component Detail</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Data List Order Component Detail</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Part Code / Part Number</label>
                    <input
                      type="hidden"
                      v-model="idordercomd"
                      autocomplete="off"
                      class="form-control"
                    />
                    <input
                      type="hidden"
                      v-model="iddaftarordd"
                      autocomplete="off"
                      class="form-control"
                    />
                    <input
                      type="text"
                      v-model="kodepartcomd"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Part Code / Part Number"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getpart()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Part Name</label>
                  <input
                    type="text"
                    readonly
                    v-model="namapartcomd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty </label>
                  <input
                    type="number"
                    v-model="qtyordercomd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Unit </label>
                  <input
                    type="text"
                    v-model="unitordercomd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/daftar-order">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdataorderprosed",
  data() {
    return {
      loading: false,
      idordercomd: "",
      iddaftarordd: "",
      kodepartcomd: "",
      namapartcomd: "",
      qtyordercomd: "",
      unitordercomd: ""
    };
  },
  created() {
    this.fetchdata();
  },
  methods: {
    async getpart() {
      this.loading = true;
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/part_component/getpart_componentbypart_no?part_no=" + this.isidata.kodepartcomd;
      const urlAPIget =
        this.$apiurl +
        "part_component/getpart_componentbypart_no?part_no=" +
        this.isidata.kodepartcomd;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Part Code / Part Number not found",
              showConfirmButton: false
            });
          } else {
            this.isidata.kodepartcomd = resp.data.data.part_no;
            this.isidata.namapartcomd = resp.data.data.name;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async fetchdata() {
      this.loading = true;
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/daftar_order_d_component/getdaftar_order_d_componentbyid?id=" + this.$route.params.id;
      const urlAPIget =
        this.$apiurl +
        "daftar_order_d_component/getdaftar_order_d_componentbyid?id=" +
        this.$route.params.id;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.idordercomd = resp.data.data.id;
          this.iddaftarordd = resp.data.data.id_daftar_order_d;
          this.kodepartcomd = resp.data.data.part_no;
          this.qtyordercomd = resp.data.data.qty;
          this.unitordercomd = resp.data.data.unit;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async updateData() {
      this.loading = true;
      var kodepartcomd = this.kodepartcomd;
      var qtyordercomd = this.qtyordercomd;
      var unitordercomd = this.unitordercomd;
      var idx = this.idordercomd;
      // var iddaftarordd = this.iddaftarordd
      if (kodepartcomd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Part Code / Part Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (qtyordercomd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (unitordercomd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Unit can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdatadetail = {
          id_daftar_order_d: this.iddaftarordd,
          part_no: this.kodepartcomd,
          qty: this.qtyordercomd,
          unit: this.unitordercomd,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        // const urlAPIUpdateDatadetail = "http://26.183.23.191/inventory/backend/inventory/api/daftar_order_d_component/updatedaftar_order_d_component/" + idx;
        const urlAPIUpdateDatadetail =
          this.$apiurl +
          "daftar_order_d_component/updatedaftar_order_d_component/" +
          idx;
        axios
          .put(urlAPIUpdateDatadetail, paramdatadetail, { headers: headers })
          .then((respn) => {
            if (respn.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
